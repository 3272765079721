import React, { useContext } from "react";
import UserContext from "../../context/UserContext";
import { useNavigate } from "react-router-dom";
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton from '@mui/joy/ListItemButton';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import ListItemContent from '@mui/joy/ListItemContent';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import FeedIcon from '@mui/icons-material/Feed';


function Home() {
  const history = useNavigate()

  const { user } = useContext(UserContext);
  if (!user) {
    return history("/login");
  }
  function loadVisits() {
    //console.log("Loading visits");
    return history("/visits");
  }
  const addVisit = (event) => {
    if (event.defaultPrevented) return;
    event.preventDefault();
    history("/visits/add");
  };
  const searchCollege = (event) => {
    if (event.defaultPrevented) return;
    event.preventDefault();
    history("/search");
  }
  const createPDF = (event) => {
    if (event.defaultPrevented) return;
    event.preventDefault();
    history("/createpdf");
  }
  const mapStudents = (event) => {
    if (event.defaultPrevented) return;
    event.preventDefault();
    history("/mapstudents");
  }
  return (
    <div>
      <List>

        <ListItem>
          <ListItemButton onClick={addVisit}>
            <ListItemDecorator>
              <AddIcon />
            </ListItemDecorator>
            <ListItemContent>
              New Visit
            </ListItemContent>
            <KeyboardArrowRightIcon />
          </ListItemButton>
        </ListItem>
        <ListItem >
          <ListItemButton onClick={loadVisits}>
            <ListItemDecorator>
              <FeedIcon />
            </ListItemDecorator>
            <ListItemContent>
              View Visits
            </ListItemContent>
            <KeyboardArrowRightIcon />
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton onClick={searchCollege}>
            <ListItemDecorator>
              <SearchIcon />
            </ListItemDecorator>
            <ListItemContent>
              Search College
            </ListItemContent>
            <KeyboardArrowRightIcon />
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton onClick={createPDF}>
            <ListItemDecorator>
              <SearchIcon />
            </ListItemDecorator>
            <ListItemContent>
              Create PDF for College Visits
            </ListItemContent>
            <KeyboardArrowRightIcon />
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton onClick={mapStudents}>
            <ListItemDecorator>
              <SearchIcon />
            </ListItemDecorator>
            <ListItemContent>
              Map students to colleges
            </ListItemContent>
            <KeyboardArrowRightIcon />
          </ListItemButton>
        </ListItem>
      </List>
    </div>
  );
}

export default Home;
