import React, { useEffect, useState } from 'react';
import { Box, Card, Grid, Typography, CircularProgress } from '@mui/joy';
import axios from 'axios';
import domain from "../../../util/domain";

export default function OverallStats() {
    const [stats, setStats] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchStats = async () => {
            try {
                const response = await axios.get(`${domain}/placements/feedback/stats`);
                setStats(response.data);
            } catch (error) {
                console.error('Error fetching stats:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchStats();
    }, []);

    if (loading) return <CircularProgress />;

    return (
        <Grid container spacing={2}>
            {stats && Object.entries(stats).map(([key, value]) => (
                <Grid xs={12} sm={6} md={4} key={key}>
                    <Card>
                        <Typography level="h4">
                            {key.split('_').map(word =>
                                word.charAt(0).toUpperCase() + word.slice(1)
                            ).join(' ')}
                        </Typography>
                        <Typography level="h2">{value}</Typography>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
}