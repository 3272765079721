import React, { useState } from 'react';
import {
    Box,
    Input,
    Table,
    Sheet,
    Typography
} from '@mui/joy';
import axios from 'axios';
import domain from "../../../util/domain";

export default function SearchFeedback() {
    const [query, setQuery] = useState('');
    const [results, setResults] = useState([]);

    const handleSearch = async () => {
        if (!query.trim()) return;

        try {
            const response = await axios.get(`${domain}/placements/feedback/search`, {
                params: { query }
            });
            setResults(response.data);
        } catch (error) {
            console.error('Error searching feedback:', error);
        }
    };

    return (
        <Box>
            <Box sx={{ mb: 2 }}>
                <Input
                    placeholder="Search by name or feedback content..."
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
                />
            </Box>

            <Sheet>
                <Table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Feedback</th>
                            <th>Status</th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {results.map(item => (
                            <tr key={item.id}>
                                <td>{item.from_name}</td>
                                <td>{item.placement_feedback}</td>
                                <td>
                                    {item.placedvector ? 'Vector Placed' :
                                        item.selfplaced ? 'Self Placed' :
                                            item.lookingfores ? 'Looking for Job' :
                                                'None'}
                                </td>
                                <td>{new Date(item.createdAt).toLocaleDateString()}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Sheet>
        </Box>
    );
}