import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import VisitsListView from '../VisitsListView';
import List from '@mui/joy/List';
import axios from 'axios';
import domain from "../../../../util/domain";
import ListItem from '@mui/joy/ListItem';
import ListItemContent from '@mui/joy/ListItemContent';
import Typography from '@mui/joy/Typography';
import HodDetailsList from '../details/HodDetailsList';
import AddIcon from '@mui/icons-material/Add';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import DeleteIcon from '@mui/icons-material/Delete';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import Button from '@mui/joy/Button';
import Box from '@mui/joy/Box';

export default function VisitHodTpoDetails() {
  const navigate = useNavigate();
  const location = useLocation();
  const [expanded, setExpanded] = useState(false);
  const [hods, setHods] = useState(null);
  const [tpos, setTpos] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openHodDeleteModal, setOpenHodDeleteModal] = useState(false);
  const [openTpoDeleteModal, setOpenTpoDeleteModal] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  const { visit } = location.state || {};
  useEffect(() => {
    loadHodsandTpos();
    // eslint-disable-next-line
  }, []);

  function loadHodsandTpos() {
    axios.get(`${domain}/hods?college_id=${visit.college_id}&visitDetails_id=${visit.id}`)
      .then((response) => {
        //console.log("response.data:HOD ", response.data);
        setHods(response.data);
      })
      .catch((error) => {
        //console.log("VisitHodTpoDetails: useEffect: error: ", error);
        setHods([]);
      });
    axios.get(`${domain}/tpos?college_id=${visit.college_id}&visitDetails_id=${visit.id}`)
      .then((response) => {
        //console.log("response.data:TPO ", response.data);
        setTpos(response.data);
      })
      .catch((error) => {
        //console.log("VisitHodTpoDetails: useEffect: error: ", error);
        setTpos([]);
      });
  }

  function deleteHod(hodItem) {
    setItemToDelete(hodItem);
    setOpenHodDeleteModal(true);
  }

  function confirmDeleteHod() {
    //console.log("Delete Hod: ", itemToDelete);
    setHods(hods.filter((hod) => hod.id !== itemToDelete.id));
    axios.delete(`${domain}/hods/${itemToDelete.id}`)
      .then((response) => {
        //console.log("response: ", response);
      })
      .catch((error) => {
        //console.log("error: ", error);
      });
    setOpenHodDeleteModal(false);
  }

  function renderHods() {
    return hods.map((hod, index) => (
      <HodDetailsList key={index} hodDetails={hod} showDetails={false} deleteAction={deleteHod} />
    ));
  }

  function deleteTpo(tpoItem) {
    setItemToDelete(tpoItem);
    setOpenTpoDeleteModal(true);
  }

  function confirmDeleteTpo() {
    //console.log("Delete TPO: ", itemToDelete);
    setTpos(tpos.filter((tpo) => tpo.id !== itemToDelete.id));
    axios.delete(`${domain}/tpos/${itemToDelete.id}`)
      .then((response) => {
        //console.log("response: ", response);
      })
      .catch((error) => {
        //console.log("error: ", error);
      });
    setOpenTpoDeleteModal(false);
  }

  function renderTpos() {
    return tpos.map((tpo, index) => (
      <HodDetailsList key={index} hodDetails={tpo} showDetails={false} deleteAction={deleteTpo} />
    ));
  }

  function addHODAction() {
    //console.log("Add HOD");
    navigate("/visits/details/addhod", { state: { visit } });
  }

  function addTPOAction() {
    //console.log("Add TPO");
    navigate("/visits/details/addtpo", { state: { visit } });
  }

  const deleteVisit = () => {
    axios.delete(`${domain}/visits/${visit.id}`)
      .then((response) => {
        //console.log("response: ", response);
        navigate("/visits");
      })
      .catch((error) => {
        //console.log("error: ", error);
      });
  }

  function expandVisitDetails(event, vd) {
    event.preventDefault();
    setExpanded(!expanded);
    //console.log("Expand Visit Details: ", vd);
  }

  const handleDeleteClick = (event) => {
    event.preventDefault();
    setOpenDeleteModal(true);
  };

  const handleDeleteConfirm = () => {
    setOpenDeleteModal(false);
    deleteVisit();
  };

  return (
    <>
      <List>
        <ListItem>
          <ListItemContent>
            <Typography level="title-sm">Visit Details</Typography>
          </ListItemContent>
          <ListItemDecorator sx={{ width: "44px" }} onClick={handleDeleteClick}>
            <DeleteIcon sx={{ width: "44px" }} />
          </ListItemDecorator>
        </ListItem>

        {(visit) && (!expanded) && <VisitsListView visit={visit} showDetails={false} isExpanded={true} action={(event) => expandVisitDetails(event, visit)} />}
        {(visit) && (expanded) && <VisitsListView visit={visit} showDetails={true} isExpanded={false} action={(event) => expandVisitDetails(event, visit)} />}

        <ListItem>
          <ListItemContent>
            <Typography level="title-sm">HOD Details</Typography>
          </ListItemContent>
          <ListItemDecorator sx={{ width: "44px" }} onClick={addHODAction}>
            <AddIcon sx={{ width: "44px" }} />
          </ListItemDecorator>
        </ListItem>
        {hods === null ? (
          <ListItem>
            <ListItemContent>
              <Typography level="body-sm">Loading HOD Details...</Typography>
            </ListItemContent>
          </ListItem>
        ) : hods.length > 0 ? (
          renderHods()
        ) : (
          <ListItem>
            <ListItemContent>
              <Typography level="body-sm">No HOD details available</Typography>
            </ListItemContent>
          </ListItem>
        )}

        <ListItem>
          <ListItemContent>
            <Typography level="title-sm">TPO Details</Typography>
          </ListItemContent>
          <ListItemDecorator sx={{ width: "44px" }} onClick={addTPOAction}>
            <AddIcon />
          </ListItemDecorator>
        </ListItem>
        {tpos === null ? (
          <ListItem>
            <ListItemContent>
              <Typography level="body-sm">Loading TPO Details...</Typography>
            </ListItemContent>
          </ListItem>
        ) : tpos.length > 0 ? (
          renderTpos()
        ) : (
          <ListItem>
            <ListItemContent>
              <Typography level="body-sm">No TPO details available</Typography>
            </ListItemContent>
          </ListItem>
        )}
      </List>

      <Modal open={openDeleteModal} onClose={() => setOpenDeleteModal(false)}>
        <ModalDialog
          variant="outlined"
          role="alertdialog"
          aria-labelledby="alert-dialog-modal-title"
          aria-describedby="alert-dialog-modal-description"
        >
          <Typography id="alert-dialog-modal-title" component="h2">
            Confirm Deletion
          </Typography>
          <Typography id="alert-dialog-modal-description" textColor="text.tertiary">
            Are you sure you want to delete this visit? This action cannot be undone.
          </Typography>
          <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end', pt: 2 }}>
            <Button variant="plain" color="neutral" onClick={() => setOpenDeleteModal(false)}>
              Cancel
            </Button>
            <Button variant="solid" color="danger" onClick={handleDeleteConfirm}>
              Delete Visit
            </Button>
          </Box>
        </ModalDialog>
      </Modal>

      <Modal open={openHodDeleteModal} onClose={() => setOpenHodDeleteModal(false)}>
        <ModalDialog
          variant="outlined"
          role="alertdialog"
          aria-labelledby="alert-dialog-modal-title"
          aria-describedby="alert-dialog-modal-description"
        >
          <Typography id="alert-dialog-modal-title" component="h2">
            Confirm HOD Deletion
          </Typography>
          <Typography id="alert-dialog-modal-description" textColor="text.tertiary">
            Are you sure you want to delete this HOD? This action cannot be undone.
          </Typography>
          <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end', pt: 2 }}>
            <Button variant="plain" color="neutral" onClick={() => setOpenHodDeleteModal(false)}>
              Cancel
            </Button>
            <Button variant="solid" color="danger" onClick={confirmDeleteHod}>
              Delete HOD
            </Button>
          </Box>
        </ModalDialog>
      </Modal>

      <Modal open={openTpoDeleteModal} onClose={() => setOpenTpoDeleteModal(false)}>
        <ModalDialog
          variant="outlined"
          role="alertdialog"
          aria-labelledby="alert-dialog-modal-title"
          aria-describedby="alert-dialog-modal-description"
        >
          <Typography id="alert-dialog-modal-title" component="h2">
            Confirm TPO Deletion
          </Typography>
          <Typography id="alert-dialog-modal-description" textColor="text.tertiary">
            Are you sure you want to delete this TPO? This action cannot be undone.
          </Typography>
          <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end', pt: 2 }}>
            <Button variant="plain" color="neutral" onClick={() => setOpenTpoDeleteModal(false)}>
              Cancel
            </Button>
            <Button variant="solid" color="danger" onClick={confirmDeleteTpo}>
              Delete TPO
            </Button>
          </Box>
        </ModalDialog>
      </Modal>
    </>
  )
}
