import React, { useEffect, useState } from 'react';
import { Box, CircularProgress } from '@mui/joy';
import { PieChart } from '@mui/x-charts';
import axios from 'axios';
import domain from "../../../util/domain";

export default function Distribution() {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchDistribution = async () => {
            try {
                const response = await axios.get(`${domain}/placements/feedback/distribution`);
                setData(response.data);
            } catch (error) {
                console.error('Error fetching distribution:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchDistribution();
    }, []);

    if (loading) return <CircularProgress />;

    return (
        <Box>
            {data && (
                <PieChart
                    series={[{
                        data: data.map(item => ({
                            id: item.status,
                            value: parseInt(item.count),
                            label: `${item.status} (${item.percentage}%)`
                        }))
                    }]}
                    height={400}
                />
            )}
        </Box>
    );
}