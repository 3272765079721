import React, { useState, useEffect } from 'react';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';

export default function CapturedLocation({ onUpdateLocation }) {

    const [geoLocation, setGeoLocation] = useState({ lat: 0, lon: 0 });
    const [isLocationCaptured, setIsLocationCaptured] = useState(false);
    useEffect(() => {
        // Capture the user's location
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                setGeoLocation({
                    lat: position.coords.latitude,
                    lon: position.coords.longitude,
                });
                //console.log("Location captured: ", geoLocation);
                if (isLocationCaptured === false) {
                    if (onUpdateLocation) {
                        onUpdateLocation({
                            lat: position.coords.latitude,
                            lon: position.coords.longitude,
                        });
                    }
                }
                setIsLocationCaptured(true);

            }, (error) => {
                console.error("Geolocation error:", error);
                alert("Failed to capture geolocation.");
            });
        } else {
            alert("Geolocation is not supported by this browser.");
        }
    }, [isLocationCaptured]);

    return (
        <Sheet variant="outlined" sx={{ marginTop: '5px', padding: '15px', borderRadius: '8px' }}>
            {geoLocation.lat && geoLocation.lon && (
                <Typography level="body-sm" sx={{ marginTop: '8px' }}>
                    Location: {`Lat: ${geoLocation.lat}, Lon: ${geoLocation.lon}`}
                </Typography>
            )}
        </Sheet>
    );
}