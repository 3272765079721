import React, { useContext, useState, useEffect } from 'react'
import Axios from "axios";
import domain from "../../../util/domain";
import UserContext from "../../../context/UserContext";
import VisitsListView from './VisitsListView';
import Box from '@mui/joy/Box';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemContent from '@mui/joy/ListItemContent';
import Typography from '@mui/joy/Typography';
import ListDivider from "@mui/joy/ListDivider";
import { useNavigate } from "react-router-dom";
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/joy';

function VisitDetails() {
    const history = useNavigate()
    const { user } = useContext(UserContext);
    //console.log("user: ", user);
    //console.log("VisitDetails.js");
    const [visitDetails, setVisitDetails] = useState([]);
    const [pageno, setPageno] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    useEffect(() => {
        if (!user) setVisitDetails([]);
        else getVisitDetails();
    }, [user]);

    async function getVisitDetails() {
        //console.log("Getting visit details");
        const visitResponse = await Axios.get(`${domain}/visits/`);
        setHasMore(visitResponse.data.length === 20);
        setVisitDetails(visitResponse.data);
    }
    function handleClick(event, visit) {
        if (event.defaultPrevented) return;
        event.preventDefault();
        history("/visits/details", { state: { visit } });
    }

    const addVisit = (event) => {
        if (event.defaultPrevented) return;
        event.preventDefault();
        history("/visits/add");
    };
    function renderVisitDetails() {
        //console.log("visitDetails: ", visitDetails);
        return visitDetails.map((visit, index) => (
            <>
                <React.Fragment key={index}>
                    <VisitsListView visit={visit} action={(event) => handleClick(event, visit)} />
                    {index !== visitDetails.length - 1 && <ListDivider inset="gutter" />}
                </React.Fragment>


            </>
        ));
    }
    const loadmore = async () => {
        //console.log("loadmore");
        setPageno(pageno + 1);
        const visitResponse = await Axios.get(`${domain}/visits?pageno=${pageno}`);
        setHasMore(visitResponse.data.length === 20);
        setVisitDetails(oldvisits => [...oldvisits, ...visitResponse.data]);
    }
    return (
        <List>
            <ListItem><ListItemContent><Typography level="title-lg">Visits</Typography></ListItemContent><ListItemDecorator sx={{ width: "44px" }} onClick={addVisit}>
                <AddIcon sx={{ width: "44px" }} />
            </ListItemDecorator></ListItem>
            {
                (visitDetails.length > 0) ?
                    <Box sx={{}}>
                        <List
                            variant="outlined"
                            aria-labelledby="ellipsis-list-demo"
                            sx={{ '--ListItemDecorator-size': '56px', borderRadius: "sm" }}
                        >
                            {renderVisitDetails()}
                            {(hasMore && visitDetails.length % 20 === 0) ? <ListItem> <ListItemContent><Typography level="title-lg"><Button variant="outlined" color="neutral" on onClick={loadmore}>View More</Button></Typography></ListItemContent></ListItem> : ""}
                        </List>
                    </Box>

                    : "No visits"
            }
        </List>
    )
}

export default VisitDetails;
