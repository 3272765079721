import React, { useState, useEffect } from 'react';
import {
    Sheet,
    Table,
    Typography,
    Box,
    CircularProgress,
    Card
} from '@mui/joy';
import Pagination from '@mui/material/Pagination';
import axios from 'axios';
import domain from "../../../util/domain";


export default function FeedbackList() {
    const [feedback, setFeedback] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const limit = 10;

    useEffect(() => {
        const fetchFeedback = async () => {
            try {
                setLoading(true);
                const offset = (page - 1) * limit;
                const response = await axios.get(`${domain}/placements/feedback/feedback`, {
                    params: { limit, offset }
                });
                setFeedback(response.data);
                // Assuming the API returns total count in headers or response
                setTotalPages(Math.ceil(response.headers['x-total-count'] / limit));
            } catch (error) {
                console.error('Error fetching feedback:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchFeedback();
    }, [page]);

    if (loading) return <CircularProgress />;

    return (
        <Box>
            <Sheet variant="outlined" sx={{ borderRadius: 'md', overflow: 'auto' }}>
                <Table stickyHeader hoverRow>
                    <thead>
                        <tr>
                            <th style={{ width: '20%' }}>Name</th>
                            <th style={{ width: '45%' }}>Feedback</th>
                            <th style={{ width: '15%' }}>Status</th>
                            <th style={{ width: '20%' }}>Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {feedback.map((item) => (
                            <tr key={item.id}>
                                <td>
                                    <Typography level="body-sm">
                                        {item.from_name}
                                    </Typography>
                                </td>
                                <td>
                                    <Typography level="body-sm">
                                        {item.placement_feedback}
                                    </Typography>
                                </td>
                                <td>
                                    <Typography
                                        level="body-sm"
                                        color={
                                            item.status === 'Vector Placed' ? 'success' :
                                                item.status === 'Self Placed' ? 'primary' :
                                                    item.status === 'Looking for Job' ? 'warning' :
                                                        'neutral'
                                        }
                                    >
                                        {item.status}
                                    </Typography>
                                </td>
                                <td>
                                    <Typography level="body-sm">
                                        {new Date(item.createdAt).toLocaleDateString()}
                                    </Typography>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Sheet>

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    mt: 2
                }}
            >
                <Pagination
                    count={totalPages}
                    page={page}
                    onChange={(event, value) => setPage(value)}
                />
            </Box>

            {feedback.length === 0 && (
                <Card
                    variant="outlined"
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        p: 3,
                        mt: 2
                    }}
                >
                    <Typography level="body-lg">
                        No feedback responses found
                    </Typography>
                </Card>
            )}
        </Box>
    );
}