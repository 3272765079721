import React, { useState } from 'react';
import { Box, Button, Grid, Input } from '@mui/joy';
import { LineChart } from '@mui/x-charts';
import axios from 'axios';
import domain from "../../../util/domain";
export default function DateRangeStats() {
    const [dateRange, setDateRange] = useState({
        start_date: '',
        end_date: ''
    });
    const [data, setData] = useState(null);

    const handleDateChange = async () => {
        try {
            const response = await axios.get(`${domain}//placements/feedback/stats/bydate`, {
                params: dateRange
            });
            setData(response.data);
        } catch (error) {
            console.error('Error fetching date stats:', error);
        }
    };

    return (
        <Box>
            <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid xs={12} sm={5}>
                    <Input
                        type="date"
                        value={dateRange.start_date}
                        onChange={(e) => setDateRange(prev => ({
                            ...prev,
                            start_date: e.target.value
                        }))}
                        label="Start Date"
                    />
                </Grid>
                <Grid xs={12} sm={5}>
                    <Input
                        type="date"
                        value={dateRange.end_date}
                        onChange={(e) => setDateRange(prev => ({
                            ...prev,
                            end_date: e.target.value
                        }))}
                        label="End Date"
                    />
                </Grid>
                <Grid xs={12} sm={2}>
                    <Button onClick={handleDateChange}>
                        Apply
                    </Button>
                </Grid>
            </Grid>

            {data && (
                <LineChart
                    series={[
                        { data: data.map(d => d.vector_placed), label: 'Vector Placed' },
                        { data: data.map(d => d.self_placed), label: 'Self Placed' },
                        { data: data.map(d => d.looking_for_job), label: 'Looking for Job' }
                    ]}
                    xAxis={[{ data: data.map(d => new Date(d.date)) }]}
                    height={400}
                />
            )}
        </Box>
    );
}