import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import createCustomQRCode from '../../inputs/createCustomQRCode';
import html2pdf from 'html2pdf.js';
import axios from 'axios';
import domain from "../../../util/domain";


export default function GeneratePDF() {
    const [imgData, setImgData] = useState(null);
    const [template, setTemplate] = useState('');
    const contentRef = useRef(null);
    const location = useLocation();
    const [page1, setPage1] = useState('');
    const [page2, setPage2] = useState('');
    const visitData = location.state?.visit;
    const [students, setStudents] = useState([]);
    const [isSelfPlaced, setIsSelfPlaced] = useState(false);
    const [courseType, setCourseType] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedYop, setSelectedYop] = useState(new Date().getFullYear() - 3);
    const [hods, setHods] = useState([]);
    const [tpos, setTpos] = useState([]);
    const [isHodSelected, setIsHodSelected] = useState(false);
    const [isTpoSelected, setIsTpoSelected] = useState(false);
    const [selectedHod, setSelectedHod] = useState('');
    const [selectedTpo, setSelectedTpo] = useState('');
    const [isGenerating, setIsGenerating] = useState(false);
    const page2footerES = `<span ><b  class="bhead">Embedded Systems Course Modules:</b><br />
    C, DS, C++, ARM, Linux, TCP/IP, RTOS, 8051, Embedded C.<br />
    <b class="bhead">Duration of the Course :</b> 6 months <br /><br /></span>
    <span><b class="bhead"> Vector India Key Highlights:</b><br />
    <ul><li>
    <b>Highest Package: </b>₹17.85 LPA</li>
    <li><b>Average Package: </b>₹4 LPA</li>
    <li><b>Exceptional placement record: </b>1512 placements achieved from May 2023 to May 2024.</li>
    <li><b>Trusted by leading companies: </b>576 MNCs recruited from Vector India during May 2023 to May 2024.</li>
    <li><b>100% Genuine Placement Assistance</b></li>
    </ul>
    </span>
    
    <div class="social-media">
        <a href="https://www.linkedin.com/company/vectorindia" target="_blank">
            <img src="linkedin.png" alt="LinkedIn" >
        </a>
        <a href="https://www.facebook.com/VectorInstitute" target="_blank">
            <img src="facebook.png" alt="Facebook" >
        </a>
        <a href="https://twitter.com/Vector_India" target="_blank">
            <img src="twitter.png" alt="X (formerly Twitter)" >
        </a>
        <a href="http://www.instagram.com/vectorindiainstitute" target="_blank">
            <img src="insta.png" alt="Instagram" >
        </a>
        <a href="https://www.youtube.com/@VectorIndia9" target="_blank">
            <img src="youtube.png" alt="YouTube" >
        </a>
    </div>
<br />
Kindly note that 2025 passouts are also eligible for the scholarship test.
<br />
<br />
<b>Thanking you</b>
<br />
<br />
<b>Regards</b><br />
Vector India
<br />
<br />`;

    const page2footerFSFull = `<span ><b class="bhead">Full Stack Development Course Modules:</b><br />
    <ul><li>
    <b class="bhead">Programming Languages:</b> Python, Java.</li>
    <li> <b class="bhead">Front End:</b> HTML, JavaScript, CSS, ReactJS, Bootstrap.</li>
    <li> <b class="bhead">Databases:</b> MySQL, MongoDB. </li>
    <li> <b class="bhead">Back End:</b> Node.js, Express.</li>
    <li> <b class="bhead">Version Control:</b> Git.</li>
    </ul>
    <b class="bhead">Duration of the Course :</b> 6 months <br /><br />
    </span>
    <span><b class="bhead"> Vector India Key Highlights:</b>
    <ul><li>
    <b>Highest Package: </b>₹17.85 LPA. <b>Average Package: </b>₹4 LPA</li>
    <li><b>Exceptional placement record: </b>1512 placements achieved from May 2023 to May 2024.</li>
    <li><b>Trusted by leading companies: </b>576 MNCs recruited from Vector India during May 2023 to May 2024.</li>
    <li><b>100% Genuine Placement Assistance</b></li>
    </ul>
    </span>
    
    <div class="social-mediaF">
        <a href="https://www.linkedin.com/company/vectorindia" target="_blank">
            <img src="linkedin.png" alt="LinkedIn" >
        </a>
        <a href="https://www.facebook.com/VectorInstitute" target="_blank">
            <img src="facebook.png" alt="Facebook" >
        </a>
        <a href="https://twitter.com/Vector_India" target="_blank">
            <img src="twitter.png" alt="X (formerly Twitter)" >
        </a>
        <a href="http://www.instagram.com/vectorindiainstitute" target="_blank">
            <img src="insta.png" alt="Instagram" >
        </a>
        <a href="https://www.youtube.com/@VectorIndia9" target="_blank">
            <img src="youtube.png" alt="YouTube" >
        </a>
    </div>
<br />
Kindly note that 2025 passouts are also eligible for the scholarship test.
<br />
<br />
<b>Thanking you</b>
<br />
<br />
<b>Regards</b><br />
Vector India
<br />
<br />`;

    const page2footerFS = `<span ><b class="bhead">Full Stack Development Course Modules:</b><br />
    <b class="bhead">Programming Languages:</b> Python, Java. <b class="bhead">Front End:</b> HTML, JavaScript, CSS, ReactJS, Bootstrap. <b class="bhead">Databases:</b> MySQL, MongoDB. <b class="bhead">Back End:</b> Node.js, Express. <b class="bhead">Version Control:</b> Git.<br />
    <b class="bhead">Duration of the Course :</b> 6 months <br /><br />
    </span>
    <span><b class="bhead"> Vector India Key Highlights:</b>
    <ul><li>
    <b>Highest Package: </b>₹17.85 LPA. <b>Average Package: </b>₹4 LPA</li>
    <li><b>Exceptional placement record: </b>1512 placements achieved from May 2023 to May 2024.</li>
    <li><b>Trusted by leading companies: </b>576 MNCs recruited from Vector India during May 2023 to May 2024.</li>
    <li><b>100% Genuine Placement Assistance</b></li>
    </ul>
    </span>
    
    <div class="social-media">
        <a href="https://www.linkedin.com/company/vectorindia" target="_blank">
            <img src="linkedin.png" alt="LinkedIn" >
        </a>
        <a href="https://www.facebook.com/VectorInstitute" target="_blank">
            <img src="facebook.png" alt="Facebook" >
        </a>
        <a href="https://twitter.com/Vector_India" target="_blank">
            <img src="twitter.png" alt="X (formerly Twitter)" >
        </a>
        <a href="http://www.instagram.com/vectorindiainstitute" target="_blank">
            <img src="insta.png" alt="Instagram" >
        </a>
        <a href="https://www.youtube.com/@VectorIndia9" target="_blank">
            <img src="youtube.png" alt="YouTube" >
        </a>
    </div>
<br />
Kindly note that 2025 passouts are also eligible for the scholarship test.
<br />
<br />
<b>Thanking you</b>
<br />
<br />
<b>Regards</b><br />
Vector India
<br />
<br />`;

    // /Vector India website url
    // vectorindia.org

    // linkedin.com
    // linkedin.com/company/vectorindia

    // facebook.com
    // facebook.com/VectorInstitute

    // instagram.com
    // instagram.com/vectorindiainstitute

    // twitter.com
    // twitter.com/Vector_India

    const studentPhoto = (student, fullWidth = false) => {
        let photoSrc = '';
        try {
            if (student.photo && student.photo.data) {
                // Convert Buffer data array to base64
                const uint8Array = new Uint8Array(student.photo.data);
                const base64String = btoa(String.fromCharCode.apply(null, uint8Array));
                photoSrc = `data:image/jpeg;base64,${base64String}`;
            }
        } catch (error) {
            console.error('Error processing photo:', error);
            photoSrc = ''; // Set to default image if there's an error
        }
        const campusName = student.companyid.replace("_FS", "");
        const fwidth = fullWidth ? `class="photo-boxesF"` : `class="photo-boxes"`;
        return `<div  ${fwidth} ><img src="${photoSrc}"  onerror="this.src='default-avatar.jpg';" />
                        <p style="margin-bottom: 5px;"> <span class="names">${student.name}</span><br />
                            <span class="names">${student.specialization} - ${student.yop}</span><br />
                            <span class="name">@${campusName}</span>
                        </p></div>`;
    }

    useEffect(() => {
        // Load HTML template
        let modifiedHtml = 'NO STUDENTS FOUND';
        if (students.length > 0) {
            let filteredStudents = students.filter(student => (courseType === false) ? !student.companyid.endsWith("_FS") : student.companyid.endsWith("_FS"));
            if (filteredStudents.length === 0) {
                modifiedHtml = "NO STUDENTS FOUND";
                setTemplate(modifiedHtml);
                return;
            }

            modifiedHtml = page1;
            if (imgData) {
                modifiedHtml = modifiedHtml.replace('{{qrcode}}', `<div class="qrphoto-boxes"><span class="
                            name">Apply Now</span> <img width="100%" src="${imgData}" alt="QR Code" /><span class="name">Scholarship upto 50%</span></div>`);
            } else {
                modifiedHtml = modifiedHtml.replace('{{qrcode}}', '');
            }
            if (visitData) {
                modifiedHtml = modifiedHtml
                    .replace('{{college_name}}', visitData.college_name || '')
                // Add more replacements as needed
            }

            if (courseType) {
                modifiedHtml = modifiedHtml.replace('{{course}}', "Full Stack Development");
                modifiedHtml = modifiedHtml.replace('{{subject}}', "Hyderabad");
            } else {
                modifiedHtml = modifiedHtml.replace('{{course}}', "Embedded Systems");
                modifiedHtml = modifiedHtml.replace('{{subject}}', "Hyderabad/Bangalore/Chennai");
            }

            if (isHodSelected) {
                var hod = hods.find(hod => hod.id === parseInt(selectedHod));

                if (hod) {
                    modifiedHtml = modifiedHtml.replace('{{hodName}}', hod.hodname || '');
                    modifiedHtml = modifiedHtml.replace('{{deptName}}', 'HOD - ' + hod.branch || '');
                }
            }
            if (isTpoSelected) {
                var tpo = tpos.find(tpo => tpo.id === parseInt(selectedTpo));
                if (tpo) {
                    modifiedHtml = modifiedHtml.replace('{{hodName}}', tpo.tponame || '');
                    modifiedHtml = modifiedHtml.replace('{{deptName}}', 'Training and Placement Officer');
                }
            }
            if (isHodSelected === false && isTpoSelected === false) {
                modifiedHtml = modifiedHtml.replace('{{deptName}}', 'The Principal');
                modifiedHtml = modifiedHtml.replace('{{hodName}}', '');
            }
            let imagebox = '';
            let fullWidth = (filteredStudents.length < 4) ? true : false;
            for (let i = 0; i < filteredStudents.length; i++) {
                if (i === 4) {
                    break;
                }
                imagebox += studentPhoto(filteredStudents[i], fullWidth);
            }
            modifiedHtml = modifiedHtml.replace('{{photoBox}}', imagebox);

            if (filteredStudents.length > 4) {
                const rows = 3; // Number of rows
                const cols = 4; // Number of columns
                const studentsPerPage = rows * cols
                let requiedNextPage = false;
                for (let i = 4; i < filteredStudents.length; i += studentsPerPage) {
                    const pageStudents = filteredStudents.slice(i, i + studentsPerPage);
                    fullWidth = ((pageStudents.length > 4 && pageStudents.length < 7)) ? true : false;
                    let pagePhotos = '';
                    for (let r = 0; r < rows; r++) {
                        const rowStudents = pageStudents.slice(r * cols, (r + 1) * cols);
                        pagePhotos += rowStudents.map(s => studentPhoto(s, fullWidth) || "").join("");
                    }
                    let npage = page2;
                    if (pageStudents.length >= 5) {
                        npage = npage.replace('{{page2footer}}', "");
                    } else {
                        npage = npage.replace('{{page2footer}}', (courseType) ? page2footerFS : page2footerES);
                    }
                    modifiedHtml += npage.replace('{{photoBox}}', pagePhotos);
                    if (pageStudents.length >= 5 && (i + studentsPerPage) >= students.length) {
                        requiedNextPage = true;
                    }
                }

                if (requiedNextPage) {
                    let lastp = page2;
                    modifiedHtml += lastp.replace('{{photoBox}}', "").replace('{{page2footer}}', (courseType) ? page2footerFSFull : page2footerES);
                }
            } else {
                let lastp = page2;
                modifiedHtml += lastp.replace('{{photoBox}}', "").replace('{{page2footer}}', (courseType) ? page2footerFSFull : page2footerES);
            }
        }
        setTemplate(modifiedHtml);
    }, [visitData, students]);

    useEffect(() => {
        const loadQRCode = async () => {
            const data = await createCustomQRCode(`https://www.vectorindia.org/registration?utm_source=${visitData?.college_code}&utm_medium=pdf&utm_campaign=vectorcv`);
            setImgData(data);
            const page1 = await fetch('/pdfpage1.html').then(response => response.text());
            const page2 = await fetch('/pdfpage2.html').then(response => response.text());
            setPage1(page1);
            setPage2(page2);
        };
        loadQRCode();
        setIsLoading(true);

    }, [visitData]);

    useEffect(() => {
        if (visitData?.id) {
            axios.get(`${domain}/hods?college_id=${visitData.id}`)
                .then((response) => {
                    //console.log("HODs data: ", response.data);
                    setHods(response.data);
                })
                .catch((error) => {
                    //console.log("Error fetching HODs: ", error);
                    setHods([]);
                });
            axios.get(`${domain}/tpos?college_id=${visitData.id}`)
                .then((response) => {
                    //console.log("TPOS data: ", response.data);
                    setTpos(response.data);
                })
                .catch((error) => {
                    //console.log("Error fetching TPOS: ", error);
                    setTpos([]);
                });
        }
    }, [visitData]);

    const handleDownloadPDF = () => {
        const element = contentRef.current;
        let filenamepdf = '';
        if (isHodSelected === false && isTpoSelected === false) {
            filenamepdf = `${visitData?.college_name}-Principal.pdf`;
        } else {
            filenamepdf = `${visitData?.college_name}-${(isHodSelected ? hods.find(hod => hod.id === parseInt(selectedHod)).hodname + " - HOD" : tpos.find(tpo => tpo.id === parseInt(selectedTpo)).tponame + " - TPO")}.pdf`;
        }
        const options = {
            margin: 0.2,
            filename: filenamepdf,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 5, logging: true, useCORS: true },
            jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' }
        };
        html2pdf().set(options).from(element).save();

    };

    const generateYearOptions = () => {
        const currentYear = new Date().getFullYear();
        const years = [];
        for (let year = currentYear; year >= currentYear - 10; year--) {
            years.push(year);
        }
        return years;
    };

    const updatePDF = () => {
        //console.log("updatePDF: selectedYop: ", selectedYop);
        setStudents([]);
        setIsLoading(true);
        setIsGenerating(true);
        axios.get(`${domain}/pdf?collegeCode=${visitData?.college_code}&yop=${selectedYop}&selfPlaced=${isSelfPlaced}`).then((response) => {
            //for testing add more students
            // const morestudents = response.data.concat(response.data);
            // const morestudents = response.data.join(response.data);
            //console.log("Studentdetails ", response.data);
            setStudents(response.data);
            setIsLoading(false);
            setIsGenerating(false);
        })
            .catch((error) => {
                //console.log("pdf students: useEffect: error: ", error);
                setStudents([]);
                setIsLoading(false);
                setIsGenerating(false);
            });
    }
    return (

        <div>
            <div>
                { }
                <span>YOP : </span>
                <select
                    value={selectedYop}
                    onChange={(e) => setSelectedYop(parseInt(e.target.value))}
                    style={{
                        padding: '5px',
                        marginRight: '10px',
                        borderRadius: '4px',
                        border: '1px solid #ccc'
                    }}
                >
                    {generateYearOptions().map(year => (
                        <option key={year} value={year}>{year}</option>
                    ))}
                </select>

                <span style={{ marginLeft: '10px' }}>HOD : </span>
                <select
                    value={selectedHod}
                    onChange={(e) => { setSelectedHod(e.target.value); setIsHodSelected(true); setIsTpoSelected(false); setSelectedTpo("Select TPO") }}
                    style={{
                        padding: '5px',
                        marginRight: '10px',
                        borderRadius: '4px',
                        border: '1px solid #ccc'
                    }}
                >
                    <option value="">Select HOD</option>
                    {hods.map(hod => (
                        <option key={hod.id} value={hod.id}>
                            {hod.hodname} - {hod.branch}
                        </option>
                    ))}
                </select>

                <span style={{ marginLeft: '10px' }}>TOP : </span>
                <select
                    value={selectedTpo}
                    onChange={(e) => { setSelectedTpo(e.target.value); setIsHodSelected(false); setIsTpoSelected(true); setSelectedHod("Select HOD") }}
                    style={{
                        padding: '5px',
                        marginRight: '10px',
                        borderRadius: '4px',
                        border: '1px solid #ccc'
                    }}
                >
                    <option value="">Select TPO</option>
                    {tpos.map(tpo => (
                        <option key={tpo.id} value={tpo.id}>
                            {tpo.tponame}
                        </option>
                    ))}
                </select>
                <input type="checkbox" name="selfPlaced" id="selfPlaced" onChange={(e) => setIsSelfPlaced(e.target.checked)} checked={isSelfPlaced} />
                <label htmlFor="selfPlaced">Self Placed</label>&nbsp;&nbsp;&nbsp;
                <input type="checkbox" name="courseType" id="courseType" onChange={(e) => setCourseType(e.target.checked)} checked={courseType} />
                <label htmlFor="courseType">Course : {courseType ? "Full Stack" : "Embedded"}</label>&nbsp;&nbsp;&nbsp;
                <button onClick={updatePDF}>View PDF</button>&nbsp;&nbsp;&nbsp;
                <button
                    onClick={handleDownloadPDF}
                    style={{
                        marginTop: '20px',
                        padding: '10px 20px',
                        backgroundColor: '#007bff',
                        color: 'white',
                        border: 'none',
                        borderRadius: '5px',
                        cursor: 'pointer'
                    }}
                >
                    Download PDF
                </button>
            </div>
            {isLoading ? <div><h2>{isGenerating ? "Generating PDF..." : "Select YOP and HOD and click on generate PDF"}</h2></div> : (students.length === 0) ? "No Students found" : ""}
            <div ref={contentRef}>
                {/* Render the template */}
                <div dangerouslySetInnerHTML={{ __html: template }} />
            </div>

        </div >
    );
}
