import React from 'react';
import { Box, Typography, Tab, TabList, TabPanel, Tabs } from '@mui/joy';
import OverallStats from './OverallStats';
import DateRangeStats from './DateRangeStats';
import FeedbackList from './FeedbackList';
import Distribution from './Distribution';
import RecentResponses from './RecentResponses';
import SearchFeedback from './SearchFeedback';

export default function PlacementFeedback() {
    const [activeTab, setActiveTab] = React.useState(0);

    return (
        <Box sx={{ width: '100%', p: 2 }}>
            <Typography level="h1" sx={{ mb: 2 }}>
                Placement Feedback Analysis
            </Typography>

            <Tabs
                value={activeTab}
                onChange={(event, newValue) => setActiveTab(newValue)}
                sx={{ bgcolor: 'background.body' }}
            >
                <TabList>
                    <Tab>Overall Stats</Tab>
                    <Tab>Date Range Analysis</Tab>
                    <Tab>Feedback List</Tab>
                    <Tab>Distribution</Tab>
                    <Tab>Recent Responses</Tab>
                    <Tab>Search</Tab>
                </TabList>

                <TabPanel value={0}>
                    <OverallStats />
                </TabPanel>
                <TabPanel value={1}>
                    <DateRangeStats />
                </TabPanel>
                <TabPanel value={2}>
                    <FeedbackList />
                </TabPanel>
                <TabPanel value={3}>
                    <Distribution />
                </TabPanel>
                <TabPanel value={4}>
                    <RecentResponses />
                </TabPanel>
                <TabPanel value={5}>
                    <SearchFeedback />
                </TabPanel>
            </Tabs>
        </Box>
    );
}
