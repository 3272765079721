import * as React from 'react';
import Avatar from '@mui/joy/Avatar';
import ListItem from '@mui/joy/ListItem';
import ListItemContent from '@mui/joy/ListItemContent';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import Typography from '@mui/joy/Typography';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import CheckIcon from '@mui/icons-material/Check';
import axios from 'axios';
import domain from "../../../../util/domain";
import { useNavigate } from "react-router-dom";
import { Modal, Box, IconButton } from '@mui/joy'; // Importing components from Joy UI
import { keyframes } from '@emotion/react'; // Importing keyframes for animations
import CloseIcon from '@mui/icons-material/Close'; // Importing a close icon from Material-UI

export default function HodDetailsList({ showDetails, hodDetails, action, deleteAction, canEdit, canDelete }) {
    const navigate = useNavigate();
    const isEditable = (canEdit === undefined) ? true : canEdit;
    const isDeletable = (canDelete === undefined) ? true : canDelete;
    const hod_name = hodDetails.hodname || hodDetails.tponame;
    const hod_email = hodDetails.hod_email || hodDetails.tpo_email;
    const hod_mobile = hodDetails.hod_mobile || hodDetails.tpo_mobile;
    const branch_name = hodDetails.branch;
    const [open, setOpen] = React.useState(false);
    const [imageSrc, setImageSrc] = React.useState('');
    const [startPosition, setStartPosition] = React.useState({ x: 0, y: 0 });
    const [isAnimating, setIsAnimating] = React.useState(false); // State to manage animation
    const [isClosing, setIsClosing] = React.useState(false); // State to manage closing

    let hod = (hodDetails.hodname) ? true : false;
    const [sd, setSd] = React.useState((showDetails === undefined) ? false : showDetails);

    function editItem(event) {
        event.preventDefault();
        navigate("/visits/details/edit", { state: { hodDetails } });
    }

    const growAnimation = keyframes`
      0% {
        opacity: 0;
        transform: translate(${startPosition.x}px, ${startPosition.y}px) scale(0.1);
      }
      100% {
        opacity: 1;
        transform: translate(0, 0) scale(1);
      }
    `;

    const shrinkAnimation = keyframes`
      0% {
        opacity: 1;
        transform: translate(0, 0) scale(1);
      }
      100% {
        opacity: 0;
        transform: translate(${startPosition.x}px, ${startPosition.y}px) scale(0.1);
      }
    `;

    const handleClick = (e) => {
        e.preventDefault();
        const rect = e.target.getBoundingClientRect();
        const clickX = rect.left + rect.width / 2;
        const clickY = rect.top + rect.height / 2;

        setStartPosition({ x: clickX, y: clickY });
        const imgpath = e.target.currentSrc.replaceAll("/100/", "/");
        setImageSrc(imgpath);
        setIsClosing(false);
        setOpen(true);
        setTimeout(() => setIsAnimating(true), 10); // Start animation after ensuring open state
    };

    const handleClose = () => {
        setIsAnimating(false); // Trigger close animation
        setIsClosing(true); // Set closing state

        setTimeout(() => {
            setOpen(false); // Hide modal after animation completes
            setIsClosing(false); // Reset closing state
        }, 500); // Matches the duration of the animation
    };

    function deleteItem(event) {
        event.preventDefault();
        axios.delete(`${domain}/${(hod) ? "hods" : "tpos"}/${hodDetails.id}`)
            .then((response) => {
                deleteAction(hodDetails);
            })
            .catch((error) => {
                //console.log("Delete error: ", error);
            });
    }

    function clickAction(event) {
        if (event.defaultPrevented) return;
        event.preventDefault();
        setSd(!sd);
    }

    return (
        <>
            <ListItem onClick={(action) ? action : clickAction}>
                {(sd) && <KeyboardArrowUp />}
                {(!sd) && <KeyboardArrowDown />}
                <ListItemDecorator>
                    <Avatar src={(hodDetails.photo_url && hodDetails.photo_url !== "") ? `${domain}/photos/100/${hodDetails.photo_url}` : "1.jpg"}
                        onClick={(hodDetails.photo_url && hodDetails.photo_url !== "") ? handleClick : () => { }}
                        sx={{ marginRight: "8px", }}
                    />
                </ListItemDecorator>
                <ListItemContent>
                    <Typography level="title-sm">{hod_name}-{hod_email}</Typography>
                    <Typography level="body-sm" noWrap>
                        {hod_mobile}-{branch_name}
                    </Typography>
                    {(sd) && <Typography level='body-sm'>Remarks : {hodDetails.remarks}</Typography>}
                    {(sd) && (hodDetails.isStudentDataShared === true) && <Typography startDecorator={<CheckIcon />} level='body-sm'>Shared Student Data</Typography>}
                </ListItemContent>
                {(isEditable) && <ListItemDecorator sx={{ width: "44px" }} onClick={(e) => editItem(e)}>
                    <EditIcon />
                </ListItemDecorator>}
                {(isDeletable) && <ListItemDecorator sx={{ width: "44px" }} onClick={(e) => deleteItem(e)}>
                    <DeleteIcon />
                </ListItemDecorator>}
            </ListItem >

            {/* Modal to show full-screen image with animation */}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: 0,
                    boxSizing: 'border-box',
                    opacity: isAnimating ? 1 : 0, // Smooth transition for visibility
                    visibility: open ? 'visible' : 'hidden', // Control visibility
                    transition: 'opacity 0.5s ease-out', // Ensure transition smoothness
                }}
            >
                <Box
                    sx={{
                        p: 2,
                        border: '1px dashed grey',
                        position: 'relative',
                        animation: `${isClosing ? shrinkAnimation : growAnimation} 0.5s ease-out`,
                        maxWidth: 'calc(100vw - 32px)',
                        maxHeight: 'calc(100vh - 32px)',
                        overflow: 'hidden',
                        padding: '16px',
                        borderRadius: '8px',
                    }}
                >
                    <IconButton
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            top: 8,
                            right: 8,
                            color: '#fff',
                            zIndex: 10,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>

                    <img
                        src={imageSrc}
                        alt="Full Screen"
                        style={{
                            maxWidth: '100%',
                            height: 'calc(100vh - 64px)',
                            objectFit: 'contain',
                            borderRadius: '8px',
                            display: 'block',
                            margin: '0 auto',
                        }}
                    />
                </Box>
            </Modal>
        </>
    );
}