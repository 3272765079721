import React, { useState } from 'react';
import axios from 'axios';
import domain from "../../../util/domain";
import {
    Box,
    Typography,
    FormControl,
    FormLabel,
    Input,
    Button,
    Sheet,
    ListItem,
    ListItemButton,
    ListItemContent,
    Radio,
    Modal,
    ModalDialog,
    DialogTitle,
    DialogContent,
    DialogActions
} from '@mui/joy';

export default function MapStudents() {
    const [colleges, setColleges] = useState([]);
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);
    const [selectedCollege, setSelectedCollege] = useState(null);
    const [collegeName, setCollegeName] = useState([]);
    const [collegeCode, setCollegeCode] = useState([]);
    const [searchResults, setSearchResults] = useState(JSON.parse(localStorage.getItem('pdfsearchResults2')) || []);
    const [selectedStudents, setSelectedStudents] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [openModal, setOpenModal] = useState(false);

    const fetchColleges = async (searchTerm = '') => {
        setSelectedCollege(null);
        try {
            setLoading(true);
            const response = await axios.get(`${domain}/students/unmapped-colleges?search=${searchTerm}`);
            //console.log(response.data);
            setColleges(response.data);
        } catch (error) {
            console.error('Error fetching colleges:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleSearch = (e) => {
        e.preventDefault();
        fetchColleges(search);
    };

    const handleAllCollegesSearch = async () => {
        try {
            const response = await axios.get(`${domain}/search/api/colleges/search`, {
                params: {
                    collegeName,
                    collegeCode,
                }
            });
            setSearchResults(response.data);
        } catch (error) {
            console.error("Error searching colleges:", error);
        }
    };

    const handleSelectAll = (e) => {
        if (e.target.checked) {
            setSelectedStudents(colleges.map(college => college.studentid));
            setSelectAll(true);
        } else {
            setSelectedStudents([]);
            setSelectAll(false);
        }
    };

    const handleSelectStudent = (studentId) => {
        setSelectedStudents(prev => {
            if (prev.includes(studentId)) {
                const newSelection = prev.filter(id => id !== studentId);
                setSelectAll(false);
                return newSelection;
            } else {
                const newSelection = [...prev, studentId];
                setSelectAll(newSelection.length === colleges.length);
                return newSelection;
            }
        });
    };

    const handleMapSelected = async () => {
        setOpenModal(true);
    };

    const handleConfirmMap = async () => {
        try {
            const response = await axios.post(`${domain}/students/map`, {
                studentIds: selectedStudents,
                collegeCode: selectedCollege.college_code
            });
            //console.log(response);
            setOpenModal(false);
            fetchColleges(search);
            setSelectedStudents([]);
            setSelectAll(false);

        } catch (error) {
            console.error("Error mapping students:", error);
        }
    };

    const handleCollegeSelection = (college) => {
        setSelectedCollege(college);
    };

    return (
        <>
            <Modal open={openModal} onClose={() => setOpenModal(false)}>
                <ModalDialog>
                    <DialogTitle>Confirm Mapping</DialogTitle>
                    <DialogContent>
                        Are you sure you want to map {selectedStudents.length} student(s) to {selectedCollege?.college_name}?
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="plain"
                            color="neutral"
                            onClick={() => setOpenModal(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="solid"
                            color="primary"
                            onClick={handleConfirmMap}
                        >
                            Confirm
                        </Button>
                    </DialogActions>
                </ModalDialog>
            </Modal>

            <div className="container-fluid">
                {/* Top Search Bar */}
                <div className="row mb-4 mt-3">
                    <div className="col-12">
                        <form onSubmit={handleSearch}>
                            <div className="input-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search students by college name..."
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                                <button type="submit" className="btn btn-primary">
                                    {loading ? 'Searching...' : 'Search'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>

                {/* Split View */}
                <div className="row" style={{ height: 'calc(100vh - 200px)' }}>
                    {/* Left Side - Students List */}
                    <div className="col-md-6 pe-md-2">
                        <div className="card h-100">
                            <div className="card-header bg-light d-flex justify-content-between align-items-center">
                                <h5 className="mb-0">Students List</h5>
                                <div>
                                    <span className="me-2">
                                        {selectedStudents.length} selected
                                    </span>

                                </div>
                            </div>
                            <div className="card-body p-0">
                                <div className="table-responsive" style={{ maxHeight: 'calc(100vh - 280px)', overflowY: 'auto' }}>
                                    <table className="table table-hover table-striped mb-0">
                                        <thead className="sticky-top bg-white">
                                            <tr>
                                                <th>
                                                    <div className="form-check">
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            checked={selectAll}
                                                            onChange={handleSelectAll}
                                                        />
                                                    </div>
                                                </th>
                                                <th>Student ID</th>
                                                <th>YOP</th>
                                                <th>Mobile</th>
                                                <th>College</th>
                                                <th>university</th>
                                                <th>collegeaddress</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {colleges.map((college) => (
                                                <tr
                                                    key={college.studentid}
                                                    onClick={() => setSelectedCollege(college)}
                                                    className={selectedCollege?.studentid === college.studentid ? 'table-primary' : ''}
                                                >
                                                    <td onClick={(e) => e.stopPropagation()}>
                                                        <div className="form-check">
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input"
                                                                checked={selectedStudents.includes(college.studentid)}
                                                                onChange={() => handleSelectStudent(college.studentid)}
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>{college.studentid}</td>
                                                    <td>{college.yop}</td>
                                                    <td>{college.mobileno}</td>
                                                    <td>{college.college}</td>
                                                    <td>{college.university}</td>
                                                    <td>{college.collegeaddress}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    {colleges.length === 0 && !loading && (
                                        <div className="text-center p-3">
                                            No students found
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Right Side - All Colleges Search */}
                    <Box sx={{ maxWidth: 600, margin: 'auto', mt: 4 }}>
                        <Typography level="h4" component="h1" sx={{ mb: 2 }}>
                            College Search
                        </Typography>
                        <Sheet variant="outlined" sx={{ p: 2, borderRadius: 'sm' }}>
                            <FormControl sx={{ mb: 2 }}>
                                <FormLabel>College Name</FormLabel>
                                <Input
                                    value={collegeName}
                                    onChange={(e) => setCollegeName(e.target.value)}
                                />
                            </FormControl>
                            <FormControl sx={{ mb: 2 }}>
                                <FormLabel>College Code</FormLabel>
                                <Input
                                    value={collegeCode}
                                    onChange={(e) => setCollegeCode(e.target.value)}
                                />
                            </FormControl>

                            <Button onClick={handleAllCollegesSearch} sx={{ mt: 2 }}>
                                Search
                            </Button>
                        </Sheet>
                        {searchResults.length > 0 && (
                            <Sheet id="search-results" variant="outlined" sx={{ mt: 4, p: 2, borderRadius: 'sm' }}>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                                    <Typography level="h6" component="h2">
                                        Search Results
                                    </Typography>
                                    {selectedCollege && (
                                        <Button
                                            size="sm"
                                            onClick={handleMapSelected}
                                        >
                                            Map Selected
                                        </Button>
                                    )}
                                </Box>
                                {searchResults.map((college) => (
                                    <ListItem key={college.id}>
                                        <ListItemButton
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: 1
                                            }}
                                            onClick={() => handleCollegeSelection(college)}
                                        >
                                            <Radio
                                                checked={selectedCollege?.id === college.id}
                                                onChange={() => handleCollegeSelection(college)}
                                                size="sm"
                                                sx={{ flexShrink: 0 }}
                                            />
                                            <ListItemContent>
                                                <Typography level="body-md">{college.college_name}</Typography>
                                                <Typography level="body-sm">Code: {college.college_code}</Typography>
                                                {college.college_address && (
                                                    <Typography level="body-sm">
                                                        Address: {college.college_address}
                                                    </Typography>
                                                )}
                                                {college.univerisity_name && (
                                                    <Typography level="body-sm">
                                                        University: {college.univerisity_name}
                                                    </Typography>
                                                )}
                                            </ListItemContent>
                                        </ListItemButton>
                                    </ListItem>
                                ))}
                            </Sheet>
                        )}
                    </Box>
                </div>
            </div>
        </>
    );
}
