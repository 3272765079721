import React, { useState, useEffect } from 'react';
import {
    Table,
    Sheet,
    Typography
} from '@mui/joy';
import axios from 'axios';
import Pagination from '@mui/material/Pagination';
import domain from "../../../util/domain";

export default function RecentResponses() {
    const [data, setData] = useState({ data: [], pagination: {} });
    const [page, setPage] = useState(1);

    useEffect(() => {
        const fetchRecent = async () => {
            try {
                const response = await axios.get(`${domain}/placements/feedback/recent`, {
                    params: { page, limit: 10 }
                });
                setData(response.data);
            } catch (error) {
                console.error('Error fetching recent responses:', error);
            }
        };

        fetchRecent();
    }, [page]);

    return (
        <Sheet>
            <Table>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Feedback</th>
                        <th>Status</th>
                        <th>Date</th>
                    </tr>
                </thead>
                <tbody>
                    {data.data.map(item => (
                        <tr key={item.id}>
                            <td>{item.from_name}</td>
                            <td>{item.placement_feedback}</td>
                            <td>
                                {item.placedvector ? 'Vector Placed' :
                                    item.selfplaced ? 'Self Placed' :
                                        item.lookingfores ? 'Looking for Job' :
                                            'None'}
                            </td>
                            <td>{new Date(item.createdAt).toLocaleDateString()}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            <Pagination
                count={data.pagination?.total_pages || 1}
                page={page}
                onChange={(event, value) => setPage(value)}
            />
        </Sheet>
    );
}