import React, { useState, useRef } from 'react';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import Button from '@mui/joy/Button';
import domain from "../../util/domain";

export default function ImageCapture({ title, onCapture, photoUrl }) {
    const [isCameraActive, setIsCameraActive] = useState(false);
    const [photo, setPhoto] = useState(null);
    const [facingMode, setFacingMode] = useState('environment');
    const [hasMultipleCameras, setHasMultipleCameras] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const videoRef = useRef(null);
    const canvasRef = useRef(null);
    const streamRef = useRef(null);

    const checkAvailableCameras = async () => {
        try {
            const devices = await navigator.mediaDevices.enumerateDevices();
            const videoDevices = devices.filter(device => device.kind === 'videoinput');
            setHasMultipleCameras(videoDevices.length > 1);
            //console.log(`Found ${videoDevices.length} video devices`);

            if (videoDevices.length === 1) {
                //console.log("Only one camera available, assuming it's front-facing");
                setFacingMode('user');
            }
        } catch (err) {
            console.error("Error checking available cameras: ", err);
            setErrorMessage("Unable to access camera information. Please check your browser settings.");
        }
    };

    const startCamera = async () => {
        try {
            await checkAvailableCameras();

            const constraints = {
                video: { facingMode: facingMode }
            };
            //console.log(`Attempting to start camera with facing mode: ${facingMode}`);

            const stream = await navigator.mediaDevices.getUserMedia(constraints);
            streamRef.current = stream;
            setIsCameraActive(true);
            setErrorMessage(null);

            setTimeout(() => {
                if (videoRef.current) {
                    videoRef.current.srcObject = stream;
                    videoRef.current.play().catch(e => console.error("Error playing video:", e));
                } else {
                    console.error("Video element is not available");
                    setErrorMessage("Unable to start the camera. Please try again.");
                }
            }, 100);
        } catch (err) {
            console.error("Error accessing camera: ", err);
            setErrorMessage("Unable to access the camera. Please check your browser settings and ensure camera permissions are enabled.");
            setIsCameraActive(false);
        }
    };

    const capturePhoto = () => {
        const video = videoRef.current;
        const canvas = canvasRef.current;
        if (!video || !canvas) {
            console.error("Video or canvas element is not available");
            return;
        }

        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        const context = canvas.getContext('2d');
        context.drawImage(video, 0, 0, canvas.width, canvas.height);

        const capturedPhoto = canvas.toDataURL('image/jpeg', 0.8);
        setPhoto(capturedPhoto);

        if (streamRef.current) {
            streamRef.current.getTracks().forEach(track => track.stop());
        }

        setIsCameraActive(false);

        if (onCapture) {
            onCapture(capturedPhoto);
        }
    };

    const recapturePhoto = () => {
        setPhoto(null);
        startCamera();
    };

    const flipCamera = () => {
        if (streamRef.current) {
            streamRef.current.getTracks().forEach(track => track.stop());
        }
        setFacingMode(prevMode => {
            const newMode = prevMode === 'user' ? 'environment' : 'user';
            //console.log(`Flipping camera to: ${newMode}`);
            return newMode;
        });
        startCamera();
    };

    return (
        <Sheet variant="outlined" sx={{ marginTop: '20px', padding: '16px', borderRadius: '8px' }}>
            <Typography level="body-md" sx={{ marginBottom: '8px' }}>
                {(title) ? title : "Capture Photo with Camera"}
            </Typography>

            {errorMessage && (
                <Typography level="body-sm" sx={{ marginBottom: '8px', color: 'error.main' }}>
                    {errorMessage}
                </Typography>
            )}

            {photo ? (
                <div>
                    <img src={photo} alt="captured" style={{ maxWidth: '100%', height: 'auto', borderRadius: '8px' }} />
                    <br />
                    <Button onClick={recapturePhoto} sx={{ marginTop: '10px' }}>
                        Capture photo again
                    </Button>
                </div>
            ) : (
                <div>
                    {photoUrl && !isCameraActive && (
                        <img alt="captured" src={`${domain}/photos/${photoUrl}`} style={{ maxWidth: '100%', borderRadius: '8px' }} />
                    )}

                    {isCameraActive ? (
                        <>
                            <video ref={videoRef} style={{ width: '100%', borderRadius: '8px', marginTop: '10px' }} autoPlay playsInline muted></video>
                            <Button onClick={capturePhoto} sx={{ marginTop: '10px' }}>
                                Capture Photo
                            </Button>
                            {hasMultipleCameras && (
                                <Button onClick={flipCamera} sx={{ marginTop: '10px', marginLeft: '10px' }}>
                                    Flip Camera
                                </Button>
                            )}
                        </>
                    ) : (
                        <>
                            <Button onClick={startCamera} sx={{ margcleinTop: '10px' }}>
                                Use Camera
                            </Button>
                            <input
                                type="file"
                                accept="image/*"
                                onChange={(e) => {
                                    const file = e.target.files[0];
                                    if (file) {
                                        const reader = new FileReader();
                                        reader.onload = (event) => {
                                            setPhoto(event.target.result);
                                            if (onCapture) onCapture(event.target.result);
                                        };
                                        reader.readAsDataURL(file);
                                    }
                                }}
                                style={{ marginTop: '10px', marginLeft: '10px' }}
                            />
                        </>
                    )}
                </div>
            )}

            <canvas ref={canvasRef} style={{ display: 'none' }}></canvas>
        </Sheet>
    );
}